import React, { useState } from 'react';
import {createRoot} from "react-dom/client";

interface Props {
    dates: {
        date: {
            date: string;
        }
    }[];
}

const EventCalendarAccordion: React.FC<Props> = (dates) => {
    if (!dates || dates.dates.length === 0) {
        return null;
    }

    const eventDates: string[] = dates.dates?.map((item) => {
        return item.date?.date?.split(' ')[0];
    })
        // Filter out invalid dates
        .filter((item) => {
            return !isNaN(new Date(item).getTime());
        })
        // Filter out dates in the past
        .filter((item) => {
            return new Date(item).getTime() >= new Date().setHours(0,0,0);
        })
        // Sort dates
        .sort((a, b) => {
            return new Date(a).getTime() - new Date(b).getTime();
        });

    const [selectedMonth, setSelectedMonth] = useState<string>(new Date(eventDates[0]).toLocaleString('nl-NL', { month: 'long' }));

    const months: string[] = [...new Set(eventDates.map(date => new Date(date).toLocaleString('nl-NL', { month: 'long' })))];

    return (
        <div>
            {months.map(month => (
                <div key={month} className={`event-calendar-month ${selectedMonth === month ? 'selected' : ''}`}>
                    <button className="accordion-button" onClick={() => setSelectedMonth(month === selectedMonth ? '' : month)}>
                        <i className="icon fas fa-angle-right"></i>
                        {month}
                    </button>
                    <ul className={`accordion-content whitespace-nowrap  ${selectedMonth === month ? 'show' : ''}`}>
                        {eventDates.filter(date => new Date(date).toLocaleString('nl-NL', { month: 'long' }) === month).map(date => (
                            <li key={date}>{new Date(date).toLocaleDateString('nl-NL', {
                                weekday: 'long',
                                day: 'numeric',
                                month: 'short',
                            })}</li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    );
};

let eventCalendarAccordionComponents = document.querySelectorAll('.event-calendar-accordion');

eventCalendarAccordionComponents.forEach((element) => {
    const root = createRoot(element);

    root.render(
        <EventCalendarAccordion
            dates={ element.getAttribute('data-dates') ? JSON.parse(element.getAttribute('data-dates')!) : [] }
        />
    );
});